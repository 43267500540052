<template>

  <head>

    <meta charset="utf-8" />
    <title>Hotline Transporters Sacco | Create an invoice</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta content="Rental management system by Nouveta LTD" name="description" />
    <meta content="Kelvin Njuguna" name="author" />
    <!-- App favicon -->
    <link rel="shortcut icon" href="assets/images/favicon.svg">

    <link href="assets/libs/select2/css/select2.min.css" rel="stylesheet" type="text/css" />

    <link href="assets/libs/bootstrap-datepicker/css/bootstrap-datepicker.min.css" rel="stylesheet" type="text/css">
    <link href="assets/libs/spectrum-colorpicker2/spectrum.min.css" rel="stylesheet" type="text/css">
    <link href="assets/libs/bootstrap-timepicker/css/bootstrap-timepicker.min.css" rel="stylesheet" type="text/css">
    <link href="assets/libs/bootstrap-touchspin/jquery.bootstrap-touchspin.min.css" rel="stylesheet" type="text/css" />
    <link rel="stylesheet" href="assets/libs/%40chenfengyuan/datepicker/datepicker.min.css">



    <!-- Bootstrap Css -->
    <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
    <!-- Bootstrap select styling -->
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
    <!-- Icons Css -->
    <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
    <!-- App Css-->
    <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
    <!-- custom Css-->
    <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />


  </head>

  <body data-sidebar="dark">



  <!-- Begin page -->
  <div id="layout-wrapper">



    <!-- ========== Left Sidebar Start ========== -->

    <NavigationBar/>

    <!-- Left Sidebar End -->

    <!-- Offender email address -->
    <div class="modal fade " id="subscribeModal" data-backdrop="static" data-keyboard="false" tabindex="-1 " role="dialog " aria-labelledby="staticBackdropLabel " aria-hidden="true ">
      <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content ">
          <div class="modal-header border-bottom-0 ">
            <button type="button " class="btn-close" data-dismiss="modal"  aria-label="Close "></button>
          </div>
          <div class="modal-body ">
            <div class="text-center mb-4 ">
              <div class="avatar-md mx-auto mb-4 ">
                <div class="avatar-title bg-light rounded-circle text-success h1 ">
                  <i class="bx bx-receipt "></i>
                </div>
              </div>

              <div class="row justify-content-center ">
                <div class="col-xl-10 ">
                  <h4 class="text-primary ">Enter Bill Number To continue</h4>
                  <p class="text-muted font-size-14 mb-4 ">
                    Enter the offender's ID Number to continue.
                  </p>

                  <form>
                    <div class="row ">
                      <div class="col-12 ">
                        <div class="mb-3 ">
                          <label for="digit1-input " class="visually-hidden ">Bill No.</label>
                          <input v-model="billNo" type="text " class="form-control form-control-lg text-center two-step " placeholder="Search for Bill">
                        </div>
                      </div>
                    </div>
                    <button @click="getBill()" type="button" class="btn btn-success btn-block w-100 " data-dismiss="modal">
                      <i class="bx bx-search-alt-2 font-size-16 align-middle me-2 "></i>
                      Search
                    </button>
                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Offender's email address -->


    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="main-content">

      <div class="page-content">
        <div class="container-fluid">

          <!-- start page title -->
          <div class="row">
            <div class="col-12">
              <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 class="mb-sm-0 font-size-18 text-capitalize">Invoice receipting</h4>

                <div class="page-title-right">
                  <ol class="breadcrumb m-0">
                    <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                    <li class="breadcrumb-item active">Receipt Bill</li>
                  </ol>
                </div>

              </div>
            </div>
          </div>
          <!-- end page title -->

          <!-- eTransactions table -->

          <div class="row">
            <!-- use this when the selections are empty -->
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex text-center align-items-center justify-content-center">
                    <div class="row d-flex text-center align-items-center justify-content-center initial-placeholder-receipting">
                      <div class="col-sm-12 col-lg-6 col-md-6">
                        <div class="p-4 border-radius border-solid border-primary border-2">
                          <div class="img mb-4 pt-3">
                            <img src="assets/images/animatedIcons/icons8-search.gif" class="img" alt="">
                          </div>
                          <div>
                            <h4 class="card-title text-uppercase text-dark">Invoice Receipting</h4>
                            <p>This section allows you to receipt existing bills. Search for unpaid invoices to proceed. You can also use this section to pay for multiple invoices at once.</p>
                            <p class="fw-semibold text-capitalize" >Search For an invoice to continue</p>

                            <button type="button"  data-toggle="modal" data-target="#subscribeModal"  class="btn btn-lg btn-success btn-block w-100 mt-5 mb-3">
                              <i class="bx bx-search-alt-2 font-size-28 align-middle me-2 "></i>
                              Search For Invoice
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end of emptiness -->
            <div class="col-12">
              <div class="row d-flex justify-content-center align-items-center">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12">
                          <div class="p-3 bg-warning bg-soft mb-4 d-flex justify-content-between align-items-center">
                            <h4 class="p-0 m-0 fw-bold text-dark">Invoice Receipting ({{invoices.length}} Invoice(s))</h4>
                            <div class="text-uppercase">
                              <button data-toggle="modal" data-target="#clearModal" type="button" class="btn btn-danger btn-block w-md text-uppercase fw-bold" style="margin-left: 10px;">
                                <i class="bx bx-trash font-size-21 align-middle me-2"></i> Clear
                              </button>

                              <button data-toggle="modal" data-target="#subscribeModal" type="button" title="You can use this if you want to include another invoice to pay along with the one you've already searched for" class="btn btn-primary btn-block w-md text-uppercase fw-bold" style="margin-left: 10px;">
                                <i class="bx bx-folder-plus font-size-21 align-middle me-2"></i> Search To Add
                              </button>



                            </div>
                          </div>
                        </div>
                        <div class="col-3 mb-4">
                          <div class="d-flex">


                            <div class="avatar-sm mx-auto me-3">
                                                            <span class="avatar-title rounded-circle bg-primary font-21">
                                                                <i class="bx bx-receipt text-white"></i>
                                                            </span>
                            </div>

                            <div class="flex-grow-1">
                              <p class="text-muted mb-0">Selected Invoices</p>
                              <h5 class="mb-0 font-14px">{{invoices.length}}</h5>
                            </div>
                          </div>
                        </div>
                        <div class="col-3 mb-4">
                          <div class="d-flex">
                            <div class="avatar-sm mx-auto me-3">
                                                            <span class="avatar-title rounded-circle bg-dark font-21">
                                                                <i class="mdi mdi-cash-multiple text-white"></i>
                                                            </span>
                            </div>
                            <div class="flex-grow-1">
                              <p class="text-muted mb-0">Invoice Amount</p>
                              <h5 class="mb-0 font-14px">KES {{formatPrice(totalDetailAmount)}}</h5>
                            </div>
                          </div>
                        </div>

                        <div class="col-3 mb-4">
                          <div class="d-flex">
                            <div class="avatar-sm mx-auto me-3">
                                                            <span class="avatar-title rounded-circle bg-info font-21">
                                                                <i class="mdi mdi-cash-check text-white"></i>
                                                            </span>
                            </div>
                            <div class="flex-grow-1">
                              <p class="text-muted mb-0">Amount Paid</p>
                              <h5 class="mb-0 font-14px text-uppercase">KES {{formatPrice(totalReceiptAmount)}}</h5>
                            </div>
                          </div>
                        </div>

                        <div class="col-3 mb-4">
                          <div class="d-flex">
                            <div class="avatar-sm mx-auto me-3">
                                                            <span class="avatar-title rounded-circle bg-danger font-21">
                                                                <i class="mdi mdi-cash-remove text-white"></i>
                                                            </span>
                            </div>
                            <div class="flex-grow-1">
                              <p class="text-muted mb-0">Remaining Balance</p>
                              <h5 class="mb-0 font-14px">KES {{formatPrice(totalBillBalance)}}</h5>
                            </div>
                          </div>
                        </div>

                      </div>

                      <div class="col-12 mt-4">

                        <div class="table-responsive border-1 mb-3"  v-for="(item, index) in invoices" :value="item" :key="index">
                          <table class="table table-nowrap vertical-align-middle table-bordered">
                            <thead class="table-danger">
                            <tr class="vertical-align-middle">
                              <th style="width: 70px;">{{index+1}}.</th>
                              <th class="text-uppercase" colspan="2">
                                Invoice No:. <span class="fw-bold text-primary">{{item.billDetails.billNo}}</span> | Date: <span class="fw-bold text-primary">{{item.billDetails.dateCreated}}</span>  | Invoice For: <span class="fw-bold text-primary">{{item.billDetails.customerName}}</span>
                              </th>
                              <th class="text-end">
                                <a @click="printBill(item.billDetails.billNo)" href="javascript: void(0);" target="new"  type="button" class="btn btn-info btn-block w-md btn-sm text-uppercase fw-bold" style="margin-left: 10px;">
                                  <i class="bx bx-printer font-size-16 align-middle me-2"></i> Print Invoice
                                </a>

                                <button @click="removeInvoice(index)" data-toggle="modal" data-target="" type="button" class="btn btn-danger btn-block w-md btn-sm text-uppercase fw-bold" style="margin-left: 10px;">
                                  <i class="bx bx-x font-size-16 align-middle me-2"></i> Remove Invoice
                                </button>
                              </th>

                            </tr>
                            </thead>
                            <thead class="bg-light">
                            <tr>
                              <th style="width: 70px;">No.</th>
                              <th colspan="2">Item Description ({{item.billInfo.length}} QTY)</th>
                              <th class="text-end">Amount</th>

                            </tr>
                            </thead>
                            <tfoot class="bg-light text-uppercase table-bordered">
                            <tr>
                              <td></td>

                              <td class="text-right fw-bold text-uppercase" colspan="2">
                                Invoice Sub Total
                              </td>
                              <td class="text-right fw-bold text-uppercase" colspan="2">
                                KES {{billDetails.detailAmount}}
                              </td>

                            </tr>


                            <tr class="table-success">
                              <td></td>

                              <td class="text-right fw-bold text-uppercase" colspan="2">
                                Outstanding Balance
                              </td>
                              <td class="border-0 text-end text-right">
                                <h5 class="m-0 text-uppercase fw-bold">KES {{formatPrice(item.billDetails.billBalance)}}</h5>
                              </td>

                            </tr>
                            </tfoot>
                            <tbody>

                            <tr v-for="(item2, index2) in item.billInfo" :value="item" :key="index2">
                              <td>{{index2+1}}</td>
                              <td colspan="2">
                                <p class="mb-0 p-0 m-0">{{item2.feeDescription}} (X{{item2.quantity}})</p>
                              </td>
                              <td class="text-end text-right">KES {{formatPrice(item2.billTotal)}}</td>

                            </tr>


                            </tbody>
                          </table>
                        </div>

                        <div class="d-flex align-items-center justify-content-center w-100 d-none">
                          <button type="button" class="btn btn-lg btn-dark waves-effect waves-light w-100">
                            <i class="mdi mdi-cash-plus font-size-16 align-middle me-2"></i> Search & Add Invoice (Multi Receipting)
                          </button>
                        </div>
                      </div>


                    </div>
                    <div class="card-footer bg-white py-4 border-top mt-5">
                      <div class="col-12">
                        <div class="p-3 bg-info bg-soft mb-0 d-flex justify-content-between align-items-center">
                          <div>
                            <h4 class="card-title">Selected Invoices Summary</h4>
                            <ul class="pl-3" v-for="(item, index) in invoices" :value="item" :key="index">
                              <li>{{item.billDetails.billNo}} - KES {{item.billDetails.billBalance}}</li>
                            </ul>
                          </div>
                          <div class="text-right">
                            <h4 class="card-title text-uppercase">Total Amount Payable ({{invoices.length}} Invoice(s))</h4>
                            <h2 class="mb-3">KES {{formatPrice(totalBillBalance)}}</h2>

                            <button type="button" data-toggle="modal" data-target="#payment-modal" class="btn btn-primary waves-effect btn-label waves-light fw-bold text-uppercase">
                              <i class="mdi mdi-send label-icon"></i> Receipt Invoice(s)</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end col -->


          </div>

          <!-- end row -->
        </div>
        <!-- container-fluid -->
      </div>
      <!-- End Page-content -->


      <!-- payment modal -->
      <div class="modal fade" id="payment-modal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-bs-labelledby="exampleModalCenterTitle" aria-bs-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header d-none">
              <span class="badge badge-soft-success text-uppercase badge font-12px bg-primary-blink text-white">Receive Payment</span>


              <button type="button" class="btn btn-light position-relative p-0 avatar-xs rounded-circle close-modal" data-dismiss="modal"  aria-label="Close">
                        <span class="avatar-title bg-transparent text-reset font-18px">
                            <i class="bx bx-x"></i>
                        </span>
              </button>

            </div>
            <div class="modal-body">
              <div class="d-flex justify-content-between align-items-center">
                <span class="badge  badge-soft-success text-uppercase badge font-12px bg-primary-blink text-white">Receive Payment</span>


                <button type="button" class="btn btn-light position-relative p-0 avatar-xs rounded-circle pull-right close-modal" data-dismiss="modal" aria-label="Close">
                        <span class="avatar-title bg-transparent text-reset font-18px">
                            <i class="bx bx-x"></i>
                        </span>
                </button>
              </div>

              <div class="payment-panel-parent">

                <div v-if="paymentPanel" class="text-center payment-active-panel d-flex flex-column justify-content-around align-items-center sent-success payment-panel">
                  <div class="success-image mb-4 pb-4 pt-4">
                    <img src="assets/images/bills.svg" height="200" alt="">
                  </div>
                  <h4 class="text-black fw-bold">{{message}}</h4>
                  <p class="text-muted mb-4">The bill has been created Successfully, you can print the bill or opt to receive payment for this bill.</p>
                  <div class="d-flex w-100 pb-2">
                    <button @click="showPaymentMethod()" type="button" class="btn btn-primary btn-block  me-3 flex-grow-1">
                      <i class="mdi-cash-check mdi font-size-16 align-middle me-2 "></i>
                      Receive Payment
                    </button>
                    <a @click="printBill()" target="_blank" data-dismiss="" type="button" class="btn btn-outline-secondary waves-effect dismin-modal">Print Bill</a>
                  </div>
                </div>

                <div v-if="paymentMethod" class="send-method payment-panel ">
                  <label for="" class="mb-0 pb-0">Payment Method</label>
                  <p><small class="text-muted">How would you like to send this money?</small></p>

                  <div>
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                          <button class="accordion-button fw-medium" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <div class="flex-shrink-0 me-3">
                              <img class="rounded-circle" src="assets/images/users/mpesa.jpg" alt="Mobile Money" height="65">
                            </div>
                            <div class="d-flex flex-column">
                              <p class="m-0 p-0 text-uppercase fw-semibold">MPESA</p>
                              <p class="mb-0 p-0"> <small>A payment request will be sent directly to the customer</small></p>
                            </div>
                          </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                          <div class="accordion-body">
                            <div>
                              <p>
                                A payment request of <strong class="text-black">KES {{totalBillBalance}}</strong> will be sent to the MPESA number you enter below.
                              </p>
                            </div>
                            <div class="form-group">
                              <label for="">MPESA Number</label>
                              <div class="form-floating mb-3">
                                <input v-model="phoneNumber" type="text" class="form-control font-21 text-success form-control-lg" id="phone-input" placeholder="Enter Name">
                                <label for="floatingnameInput">Phone No.</label>
                              </div>

                              <button @click="showTransactionSummary('MPESA')" type="button" class="btn btn-primary btn-block  me-3 flex-grow-1">
                                <i class="mdi-cash-check mdi font-size-16 align-middle me-2 "></i>
                                Send Payment Request
                              </button>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                          <button class="accordion-button fw-medium collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <div class="flex-shrink-0 me-3">
                              <img class="rounded-circle" src="assets/images/users/money.jpg" alt="Mobile Money" height="65">
                            </div>
                            <div class="d-flex flex-column">
                              <p class="m-0 p-0 text-uppercase fw-semibold">bank Receipting</p>
                              <p class="mb-0 p-0"> <small>Enter the receipt number here to confirm payment for this transaction.</small></p>


                            </div>
                          </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                          <div class="accordion-body">
                            <div class="form-group">
                              <label for="">Enter The Bank's Receipt Number</label>
                              <div class="form-floating mb-3">
                                <input v-model="paymentData.bankReceiptNo" type="text" class="form-control font-21 text-success form-control-lg" id="phone-input" placeholder="Enter Name">
                                <label for="floatingnameInput">Bank Ref No.</label>
                              </div>

                              <button @click="showTransactionSummary('BANK')" class="btn w-100 btn-warning mt-3 btn-bank">
                                Continue
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div v-if="transactionSummary" class="transaction-summary payment-panel">
                  <label for="">Transaction Breakdown</label>
                  <div class="border p-4 rounded ">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="text-muted ">
                          BillO NO.
                        </div>
                      </div>

                      <div class="col-lg-6 align-self-end">
                        <div class=" text-right text-black">
                          {{billNumbers}}
                        </div>
                      </div>


                      <div class="col-lg-6">
                        <div class="text-muted mt-2">
                          Payment Method
                        </div>
                      </div>

                      <div class="col-lg-6 align-self-end">
                        <div class=" text-right text-black">
                          {{paymentData.paymentMode}}
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="text-muted mt-2">
                          Transaction Amount
                        </div>
                      </div>

                      <div class="col-lg-6 align-self-end">
                        <div class=" text-right text-black">
                          KES {{totalBillBalance}}
                        </div>
                      </div>


                      <div class="col-12 text-black"><hr class="mb-0 pb-0"></div>

                      <div class="col-lg-6 text-uppercase">
                        <div class="text-muted mt-2 font-21">
                          Total
                        </div>
                      </div>

                      <div class="col-lg-6 align-self-end text-uppercase">
                        <div class=" text-right text-black font-21 fw-bold">
                          KES {{totalBillBalance}}
                        </div>
                      </div>
                    </div>

                    <div class="mpesa-confirmation ">
                      <div v-if="paymentData.paymentMode==='MPESA'">
                        <p class="text-muted mt-2">A payment request of <strong class="text-black">KES {{totalBillBalance}}</strong> will be sent to <strong class="text-black"> phone number ({{ phoneNumber }})</strong> soon after you click the <strong class="text-black text-capitalize">Send Request</strong> button bellow.
                          <br>
                          <br>
                          Remember to <strong class="text-black">Check your phone</strong> to confirm payment by entering your Mpesa pin.</p>
                      </div>

                      <div>{{message}}  </div>
                      <br>
                      <button @click="makePayment()" class="btn btn-primary btn-flex flex-grow-1 waves-effect btn-send waves-light text-center w-100">
                        <div class="d-flex justify-content-center align-items-center">
                          <div class="stk-timer-container d-none justify-content-center align-items-center">
                            <span class="mdi mdi-timer-outline font-16px"></span>
                            <span class="stk-timer px-2"></span>
                          </div>
                          <div class="justify-content-center align-items-center d-flex">
                            <span class="px-2">Send Request</span>
                            <div class="flip-x"><i class="mdi mdi-reply ms-3 font-16px"></i></div>
                          </div>
                        </div>
                      </button>
                    </div>


                  </div>
                </div>

                <div v-if="transactionCompleted" class="text-center d-flex flex-column justify-content-around align-items-center sent-success payment-panel">
                  <div class="success-image mb-4">
                    <img src="assets/images/payment-confirmation-images/sent.svg" height="200" alt="">
                  </div>
                  <h4 class="text-blink-primary fw-bold">Transaction Complete</h4>
                  <p class="text-muted mb-4"><strong class="text-black">KES {{receiptDetails.receiptAmount}}</strong> has been received for <strong class="text-black">Bill No. {{receiptDetails.billNo}}</strong></p>
                  <button @click="printReceipt()" type="button" class="btn btn-primary btn-block  me-3 flex-grow-1">
                    <i class="mdi-cash-check mdi font-size-16 align-middle me-2 "></i>
                    Print Receipt
                  </button>
                </div>



              </div>
            </div>
            <div class="modal-footer d-flex d-none bill-modal-footer">
              <button href="javascript: void(0);" disabled class="btn btn-outline-light waves-effect waves-light payment-prev"> <i class="mdi mdi-arrow-left ms-1"></i>Previouse</button>
              <button href="javascript: void(0);" class="btn btn-primary waves-effect waves-light payment-next">Next <i class="mdi mdi-arrow-right ms-1"></i></button>
              <button href="javascript: void(0);" class="btn btn-primary btn-flex flex-grow-1 waves-effect waves-light text-center d-none">
                <div class="d-flex justify-content-center align-items-center"> <span>Send Money</span> <div class="flip-x"><i class="mdi mdi-reply flip-x ms-3 font-16px"></i></div></div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- end of payment modal -->

      <!-- delete modal -->
      <div class="modal fade" id="clearModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content pt-4">

            <div class="modal-body ">
              <div class="text-center mb-4 ">


                <div class="d-flex pb-4 align-items-center justify-content-center">
                  <div>
                    <img src="assets/images/animatedIcons/icons8-list-is-empty.gif" class="img" alt="">
                  </div>
                </div>

                <div class="row justify-content-center ">
                  <div class="col-xl-10 ">
                    <h4 class="text-primary text-uppercase">Start Afresh</h4>
                    <p class="text-muted font-size-14 mb-4 ">
                      Are you sure you want to clear the previously searched invoices and start afresh?
                    </p>

                    <form>

                      <div class="d-flex justify-content-between">
                        <button @click="clearInvoices()" type="button" class="btn btn-danger btn-block w-100" data-dismiss="modal">

                          Clear Selections
                        </button>

                        <button type="button" class="btn btn btn-outline-secondary waves-effect btn-block w-100" data-dismiss="modal">

                          Close
                        </button>
                      </div>
                    </form>

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!-- end of delete modal -->





      <footer class="footer ">
        <div class="container-fluid ">
          <div class="row ">
            <div class="col-sm-6 ">

            </div>
            <div class="col-sm-6 ">
              <div class="text-sm-end d-sm-block ">

              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
    <!-- end main content-->

  </div>
  <!-- END layout-wrapper -->

  <!-- Right bar overlay-->
  <div class="rightbar-overlay "></div>


  </body>


</template>

<script>
import NavigationBar from "@/components/Navigation.vue";
import {biller, execute, getRight, parking, rent, trade} from "@/api";
export default {
  name: "ReceiptsNew",
  components: {NavigationBar},
  data(){
    return{
      billNumbers:'',
      totalBillBalance: '0',
      totalReceiptAmount: '0',
      totalDetailAmount: '0',
      confirmCash: false,
      phoneNumber:'',
      transactionCompleted: false,
      transactionSummary: false,
      paymentPanel: true,
      paymentMethod: false,
      paymentData: {
        eCitizenReceiptNo: null,
        bankReceiptNo: null,
        accNo: null,
        payBillNo: null,
        amount: null,
        phoneNumber: null,
        success: false,
        paymentMode:null,
        sessionIdNo:null,
        sessionPhoneNumber:null,
        sessionNames:null
      },
      billNo:'',
      billDetails:{
        id: "",
        billNo: "",
        incomeTypeID: "",
        incomeTypeDescription: "",
        costCenterNo: "",
        accountNo: ",",
        description: "",
        feeID: "",
        detailAmount: "",
        receiptAmount: "",
        billBalance: "",
        wardID: "",
        subCountyID: "",
        status: "",
        dateCreated: ""
      },
      billInfo:[],
      message:null,
      invoices:[
        {
          billDetails:{
            id: "",
            billNo: "",
            incomeTypeID: "",
            incomeTypeDescription: "",
            costCenterNo: "",
            accountNo: ",",
            description: "",
            feeID: "",
            detailAmount: "",
            receiptAmount: "",
            billBalance: "",
            wardID: "",
            subCountyID: "",
            status: "",
            dateCreated: ""
          },
          billInfo:[],
        }
      ],
      receiptDetails: {
        id: '',
        receiptNo: null,
        billNo: '',
        detailAmount: '',
        receiptAmount: '',
        billBalance: '',
        costCenterNo: '',
        accountNo: '',
        incomeTypeDescription: '',
        feeID: '',
        wardID: '',
        subCountyID:'',
        currency: '',
        source: '',
        transactionCode: '',
        paidBy: '',
        dateCreated: '',
        dateModified: '',
        createdBy: '',
        modifiedBy: '',
        isActive: '',
        status: ''
      },
      receiptInfos: [
        {
          id: '',
          receiptNo: '',
          billNo: '',
          billTotal: '',
          receiptAmount: '',
          billBalance: '',
          customer: '',
          clientPhoneNo: '',
          description: '',
          receiptDate: '',
          printCount: '',
          wardID: '',
          subCountyID: '',
          dateCreated: '',
          dateModified: '',
          createdBy: '',
          modifiedBy: '',
          printedBy: '',
          updated: '',
          isActive: '',
          status: ''
        }],
    }
  },
  mounted() {
    this.menuAccess()
    this.invoices.splice(0);
  },
  watch: {

  },
  methods:{
    menuAccess(){

      const data = new FormData();
      data.append("function", "menuAccess");
      data.append("menu", "Receipts New");
      data.append("sessionNames", sessionStorage.getItem("sessionNames"));
      data.append("sessionIdNo",  sessionStorage.getItem("sessionIdNo"));
      execute(data,biller)
          .then(() => {
            // alert(res.data.message);
          })
          .catch(() => {
            // alert(e.message);
          });

    },
    getRight(type){
      return getRight(type)
    },
    formatPrice(value) {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'KES',
        minimumFractionDigits: 2
      });
      return formatter.format(value);
    },
    removeInvoice(index) {

      this.invoices.splice(index, 1);
       this.totalsInvoicesSummary()


    },
    getBill(){
      if(this.billNo === ''){
        alert('Please Enter Bill No')
      }else {
        const data = new FormData();
        data.append("function", "getBill");
        data.append("billNo", this.billNo);
        execute(data,biller)
            .then((res) =>{
              if (res.data.success) {
                this.billNo =''

                this.billDetails = res.data.data.billDetails
                this.billInfo = res.data.data.billInfo

                // Check if the invoice already exists based on a unique identifier like 'id' or 'billNo'
                const invoiceExists = this.invoices.some(invoice => invoice.billDetails.id === res.data.data.billDetails.id);

                if (!invoiceExists) {
                  // Only add the invoice if it doesn't already exist
                  this.invoices.push(res.data.data);
                } else {
                  console.log('Invoice already exists!');
                }

                this.totalsInvoicesSummary()

              }else{
                this.message =res.data.message
              }
            })
            .catch((e) => {
              //alert(e.message);
              this.message =e.message
            });
      }

    },

    totalsInvoicesSummary(){

      this.totalBillBalance = this.invoices.reduce((total, invoice) => {
        return total + parseFloat(invoice.billDetails.billBalance || 0);
      }, 0);

      this.totalDetailAmount = this.invoices.reduce((total, invoice) => {
        return total + parseFloat(invoice.billDetails.detailAmount || 0);
      }, 0);

      this.totalReceiptAmount = this.invoices.reduce((total, invoice) => {
        return total + parseFloat(invoice.billDetails.receiptAmount || 0);
      }, 0);

        // Extract the billNo from each invoice and join them into a comma-separated string
      this.billNumbers = this.invoices.map(invoice => invoice.billDetails.billNo).join(', ');


      this.paymentData.amount = this.totalBillBalance
      this.paymentData.accNo = this.billNumbers;
      this.paymentData.sessionNames = sessionStorage.getItem("sessionNames")
      this.paymentData.sessionIdNo = sessionStorage.getItem("sessionIdNo")
      this.paymentData.sessionPhoneNumber = sessionStorage.getItem("sessionPhoneNumber")
    },
    clearInvoices(){
      this.invoices.splice(0)
      this.totalsInvoicesSummary()

    },
    showPaymentMethod(){
      this.paymentMethod = true;
      this.paymentPanel = false;
      this.transactionSummary = false;
      this.transactionCompleted = false
    },
    printBill(billNo){
      localStorage['params'] = JSON.stringify({
        billNo: billNo
      })
      const routeData = this.$router.resolve({name: 'bill'});
      window.open(routeData.href, '_blank');
    },
    printReceipt() {
      if (this.receiptDetails.receiptNo == null) {
        alert("Try again later");
      } else {
        //  this.$refs.closeReceived.click();
        localStorage['params'] = JSON.stringify({
          receiptNo: this.receiptDetails.receiptNo
        })
        const routeData = this.$router.resolve({name: 'receipt'});
        window.open(routeData.href, '_blank');
      }
    },
    showTransactionSummary(paymentMode){

      this.paymentData.paymentMode = paymentMode

      if(paymentMode === "MPESA") {
        if (this.phoneNumber == null) {
          alert("Enter phone number");
          return;
        }
      }

      if(paymentMode === "BANK") {
        if (this.paymentData.bankReceiptNo == null) {
          alert("Enter Bank Receipt No");
          return;
        }
      }

      if(paymentMode === "E-CITIZEN") {
        if (this.paymentData.eCitizenReceiptNo == null) {
          alert("Enter E-CITIZEN Receipt No");
          return;
        }
      }

      this.paymentMethod = false;
      this.paymentPanel = false;
      this.transactionSummary = true;
      this.transactionCompleted = false
    },
    makePayment() {
      if(this.paymentData.paymentMode === "MPESA") {
        if (this.phoneNumber == null) {
          alert("Enter phone number");
          return;
        }
      }

      if(this.paymentData.paymentMode === "BANK") {
        if (this.paymentData.bankReceiptNo == null) {
          alert("Enter Bank Receipt No");
          return;
        }
      }

      if(this.paymentData.paymentMode === "E-CITIZEN") {
        if (this.paymentData.eCitizenReceiptNo == null) {
          alert("Enter E-CITIZEN Receipt No");
          return;
        }
      }

      this.message = "Payment Request sent";
      this.paymentData.phoneNumber = this.phoneNumber

      const data = new FormData();
      data.append("function", "makePayment");
      data.append("paymentData", JSON.stringify(this.paymentData));
      data.append("bulkPayment", "true");

      let stream = null;
      if (this.paymentData.accNo.startsWith("SBP")) {
        stream = trade;
      }
      if (this.paymentData.accNo.startsWith("PKN")) {
        stream = parking;
      }

      if (this.paymentData.accNo.startsWith("RNT")) {
        stream = rent;
      }

      if (this.paymentData.accNo.startsWith("BLL")) {
        stream = biller;
      }

      execute(data, stream)
          .then((res) => {
            if (res.data.success) {
              this.paymentMethod = false;
              this.paymentPanel = false;
              //start checking payment
              this.timer = setInterval(() => {

                this.checkPayment();
              }, 3000);
            } else {
              this.rate = null;
              alert(res.data.message);
            }
          })
          .catch((e) => {
            alert(e.message);
          });
    },
    checkPayment() {
      this.randomNumber = Math.floor(Math.random() * 3) + 1
      if(this.randomNumber === 1){
        this.message = "Waiting for payment.";
      }
      if(this.randomNumber === 2){
        this.message = "Waiting for payment..";
      }
      if(this.randomNumber ===3){
        this.message = "Waiting for payment...";
      }
      const data = new FormData();
      data.append("function", "checkPayment");
      data.append("accountRef", this.paymentData.accNo);

      let stream = null;
      if (this.paymentData.accNo.startsWith("SBP")) {
        stream = trade;
      }
      if (this.paymentData.accNo.startsWith("PKN")) {
        stream = parking;
      }
      if (this.paymentData.accNo.startsWith("RNT")) {
        stream = rent;
      }
      if (this.paymentData.accNo.startsWith("BLL")) {
        stream = biller;
      }
      execute(data, stream)
          .then((res) => {
            if (res.data.success) {
              clearInterval(this.timer);
              this.receipt = true;
              this.paymentData.success = false;
              this.transaction = res.data.data.transaction;
              this.receiptDetails = res.data.data.receipt.receiptDetails
              //Close the modal
              this.transactionSummary = false
              this.transactionCompleted = true
            }
          })
          .catch((e) => {
            alert(e.message);
          });
    },

  }
}
</script>

<style scoped>

</style>