<template>

  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <link rel="shortcut icon" href="images/favicon.png" type="image/x-icon">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Yellowtail&display=swap" rel="stylesheet">

  </head>

  <body style="padding: 0px; margin: 0px; font-family: 'Montserrat', sans-serif; background-color: #a2a2a21c;box-sizing: border-box;">

  <button class="print-btn" onclick="window.print()"><img src="assets/printer.svg" alt="Printer Icon"></button>
  <button class="print-btn email-doc" title="send On Email"><img src="assets/email-icon.png" alt="Printer Icon "></button>


  <!-- page one -->
  <page class="page" style="width: 210mm; height:297mm; display: flex; position: relative; flex-flow: column; background-color: white;">
    <!-- main content -->
    <div style="padding: 8.5mm;">
      <header style="display: flex; height: 47mm; align-items: center; position: relative; width: 100%; justify-content: space-between;">
        <div>
          <img :src="county.stateLogo" style="height: 36mm; margin-right: 0mm;">
        </div>
        <div style="text-align: center; margin-right: 8.5mm; margin-left: 8.5mm;">
          <h2 style="
                        margin-top: 0px;
                        font-size: 26px;
                        text-transform: uppercase;
                        color: #4ca82c;
                        font-weight: 700;
                        margin-bottom: 1.2mm;
                        padding-bottom: 0px;

                    ">
            County Government <br> Of {{county.countyName}}
          </h2>

          <h4 style="padding: 0px;
                    margin: 0px;
                    font-size: 18px;
                    font-weight: bold; text-transform: uppercase; margin-bottom: 4.5mm;">
            ({{selectedSubCounty}})
          </h4>



          <h4 style="padding: 0px;
                    margin: 0px;
                    font-size: 14px;
                    font-weight: 400;">
            {{county.address}}<br>
            Email: {{county.email}}
          </h4>

          <h4 style="padding: 0px;
                    margin: 0px;
                    font-size: 13px;
                    font-weight: 400; font-style: italic; font-family: 'Times New Roman', Times, serif; margin-top: 2mm;">

            <span style="font-style: italic; font-family: 'Times New Roman', Times, serif; margin-right: 3mm;">{{county.tagline}}</span>
            <span style="font-style: italic; font-family: 'Times New Roman', Times, serif;">{{county.tagline2}}</span>
          </h4>

        </div>
        <div>
          <img :src="county.logo" style="height: 36mm; margin-right: 0mm;">
        </div>

      </header>
      <div>
        <img src="assets/green-line.png" style="width: 100%;">
      </div>

      <!-- contact information and QR code -->
      <div style="display: flex; justify-content: center;">

        <!-- contact information -->
        <div style="font-size: 12px;">
          <h2 style="
                        margin-top: 0px;
                        font-size: 18px;
                        text-transform: uppercase;
                        color: #4ca82c;
                        font-weight: 700;
                        margin-bottom: 3.3mm;
                        margin-top:3.3mm;
                        padding-bottom: 0px;
                        text-align: center;

                    ">
            Single Business Permit Invoicing Report
          </h2>

          <p style="padding: 0px; margin: 0px; padding-bottom: 1.5mm; text-align: center; text-transform: capitalize; font-family: 'Times New Roman', Times, serif; font-style: italic; font-weight: bold;">{{recordYear}} business report showing invoicing status for {{Number(recordYear)+1}}.</p>

        </div>


      </div>

      <!-- the table -->

      <!-- the table contents -->
      <div style="display: flex; justify-content: center;">

        <!-- contact information -->
        <div style="font-size: 12px;">
          <h2 style="
                        margin-top: 0px;
                        font-size: 13px;
                        text-transform: uppercase;
                        color: #4ca82c;
                        font-weight: 700;
                        margin-bottom: 3.3mm;
                        margin-top:3.3mm;
                        padding-bottom: 0px;
                        text-align: center;
                        margin-top: 9mm;

                    ">
            Summary Report
          </h2>
        </div>


      </div>

      <table style="margin-top:2.5mm; margin-bottom: 2.5mm; border-spacing: initial; min-height: 70mm; text-transform: capitalize; width: 100%;">
        <thead class="" style="text-transform: uppercase;
                font-weight: 700;
                font-size: 10.5px;
                background: #eaf6e9;
                padding: 2.5mm;">
        <tr>
          <th style="padding: 2.5mm; width: 10mm;">#</th>
          <th style="padding: 2.5mm;">Sub County</th>
          <th style="padding: 2.5mm;width: 35mm;">Total Businesses</th>
          <th style="padding: 2.5mm;">Invoiced</th>
          <th style="padding: 2.5mm;">Pending</th>
          <th style="padding: 2.5mm;">Archived</th>
          <th style="text-align: right; padding: 2.5mm; width: 50mm;">Collected Revenue</th>
        </tr>

        </thead>
        <tbody style="font-size: 11px;">
        <tr  v-for="(item, index) in subCountiesRevenue" :value="item" :key="index">
          <td style="padding: 2.5mm;">{{index+1}}.</td>
          <td style="padding: 2.5mm;">{{item.subCountyName}}</td>
          <td style="padding: 2.5mm; text-align: center">{{item.recordCount}}</td>
          <td style="padding: 2.5mm; text-align: center">{{item.invoicedCount}}</td>
          <td style="padding: 2.5mm; text-align: center">{{item.pendingCount}}</td>
          <td style="padding: 2.5mm; text-align: center">{{item.archivedCount}}</td>
          <td style="text-align: right; padding: 2.5mm;">
            <strong>KES {{numberFormat(item.totalReceiptAmount)}}</strong>
          </td>
        </tr>



        </tbody>
        <thead class="" style="text-transform: uppercase;
                font-weight: 700;
                font-size: 12px;
                background: #eaf6e9;
                padding: 2.5mm;">
        <tr>
          <th  style="padding: 2.5mm; text-align: left;" colspan="2" >Report Total</th>
          <th style="padding: 2.5mm; text-align: center;">{{numberFormat(totalRecordCount)}}</th>
          <th style="padding: 2.5mm; text-align: center;">{{numberFormat(totalInvoicedCount)}}</th>
          <th style="padding: 2.5mm; text-align: center;">{{numberFormat(totalPendingCount)}}</th>
          <th style="padding: 2.5mm; text-align: center;">{{numberFormat(totalArchivedCount)}}</th>

          <th style="text-align: right; padding: 2.5mm; width: 50mm;">KES {{numberFormat(totalAmount)}}</th>
        </tr>

        </thead>
      </table>

      <div>
        <img src="assets/green-line.png" style="width: 100%; margin-bottom: 4mm;">
      </div>

      <div style="display: flex; justify-content: center;">

        <!-- contact information -->
        <div style="font-size: 12px;">
          <h2 style="
                        margin-top: 0px;
                        font-size: 13px;
                        text-transform: uppercase;
                        color: #4ca82c;
                        font-weight: 700;
                        margin-bottom: 3.3mm;
                        margin-top:3.3mm;
                        padding-bottom: 0px;
                        text-align: center;
                        margin-top: 9mm;

                    ">
            Detailed Report
          </h2>

        </div>

      </div>

      <table style="margin-top:2.5mm; margin-bottom: 2.5mm; border-spacing: initial; min-height: 70mm; text-transform: capitalize; width: 100%;">
        <thead class="" style="text-transform: uppercase;
                font-weight: 700;
                font-size: 12px;
                background: #eaf6e9;
                padding: 2.5mm;
                white-space: nowrap;">
        <tr>
          <td style="padding: 2.5mm; width: 10mm;">#</td>
          <td style="padding: 2.5mm; width: 200mm">Name</td>
          <td style="padding: 2.5mm; white-space: nowrap;">Business No.</td>
          <td style="padding: 2.5mm; white-space: nowrap;">Sub County</td>
          <td style="padding: 2.5mm; white-space: nowrap;">Renewal Status</td>
          <td style="text-align: right; padding: 2.5mm; width: 50mm;">Amount</td>
        </tr>

        </thead>
        <tbody style="font-size: 11px;">

        <tr :key="index" :value="item" v-for="(item, index) in entries.slice(0, displayCount)">
          <td style="padding: 2.5mm;">{{ index + 1 }}</td>
          <td style="padding: 2.5mm; width: 80mm; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 4; line-height: 1.2em; max-height: 5.6em;">
            {{ item.businessName }} | {{item.ownerPhone}} <br>
            <small>{{ item.feeDescription }} (<b>{{item.brimsCode}}</b>)</small>
          </td>
          <td style="padding: 2.5mm;">{{ item.businessID }}</td>
          <td style="padding: 2.5mm; white-space: nowrap; font-size: 9px">{{ item.subCountyName }}</td>
          <td style="padding: 2.5mm; font-size: 11px">{{ item.renewalStatus}}</td>
          <td style="text-align: right; padding: 2.5mm;">
            <strong :style="{ color: item.receiptAmount < 1 ? '' : 'green' }">
              {{ numberFormat(item.newAmount) }}
            </strong>

          </td>
        </tr>


        </tbody>

      </table>

      <div>
        <img src="assets/green-line.png" style="width: 100%; margin-bottom: 4mm;">
      </div>


    </div>


  </page>

  <!-- middle pages -->
  <page v-for="(pageEntries, pageIndex) in paginatedEntries" :key="pageIndex" class="page"
        style="width: 210mm; height: 297mm; display: flex; position: relative; flex-flow: column; background-color: white; margin-top: 20mm;">
    <div style="padding: 8.5mm;">
      <div style="display: flex; justify-content: space-between;">
        <h4 style="padding: 0px; margin: 0px; font-size: 12px; font-weight: bold; text-align: left; color: #4aaa23; text-transform: uppercase;">
          Paid Businesses Report
        </h4>
        <h4 style="padding: 0px; margin: 0px; font-size: 12px; font-weight: bold; text-transform: uppercase; text-align: right;">
          <span style="color: #4aaa23;">Page | </span><span style="color: black;">{{ pageIndex + 1 }}</span>
        </h4>
      </div>

      <div>
        <img src="assets/green-line.png" style="width: 100%;">
      </div>

      <!-- Table for this page -->
      <table style="margin-top: 2.5mm; margin-bottom: 2.5mm; border-spacing: initial; min-height: 70mm; text-transform: capitalize; width: 100%;">
        <thead class="" style="text-transform: uppercase; font-weight: 700; font-size: 11px; background: #eaf6e9; padding: 2.5mm; white-space: nowrap;">
        <tr>
          <td style="padding: 2.5mm; width: 10mm;">#</td>
          <td style="padding: 2.5mm; width: 200mm;">Name</td>
          <td style="padding: 2.5mm; white-space: nowrap;">Business No.</td>
          <td style="padding: 2.5mm; white-space: nowrap;">Sub County</td>
          <td style="padding: 2.5mm; white-space: nowrap;">Renewal Status</td>
          <td style="text-align: right; padding: 2.5mm; width: 50mm;">Amount</td>
        </tr>
        </thead>
        <tbody style="font-size: 11px;">
        <tr :key="index" :value="item" v-for="(item, index) in pageEntries">
          <td style="padding: 2.5mm;">{{ (pageIndex * pageSize) + (index + displayCount+1) }}</td> <!-- Start numbering from 7 on subsequent pages -->
          <td style="padding: 2.5mm; width: 80mm; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 4; line-height: 1.2em; max-height: 5.6em;">
            {{ item.businessName }} | <small class="text-info"> {{item.ownerPhone}} </small><br>
            <small>{{ item.feeDescription }} (<b>{{item.brimsCode}}</b>)</small>
          </td>
          <td style="padding: 2.5mm;">{{ item.businessID }}</td>
          <td style="padding: 2.5mm; white-space: nowrap; font-size: 9px">{{ item.subCountyName }}</td>
          <td style="padding: 2.5mm; font-size: 11px">{{ item.renewalStatus }}</td>
          <td style="text-align: right; padding: 2.5mm;">
            <strong :class="item.receiptAmount > 0 ? '' : 'text-success'">
              {{ numberFormat(item.newAmount) }}
            </strong>
          </td>
        </tr>
        </tbody>
      </table>

      <div>
        <img src="assets/green-line.png" style="width: 100%; margin-bottom: 4mm;">
      </div>

    </div>
  </page>
  <!-- final page -->

  </body>

</template>

<script>
/* eslint-disable */
import {biller, execute, trade} from "@/api";

export default {
  name: "SubCountyReport",
  data() {
    return {
      recordYear:'',
      selectedSubCounty:'',
      county: {
        id: null,
        countyID: null,
        countyName: null,
        tagline: null,
        smsusername: null,
        smspassword:null,
        contact: null,
        center: null,
        address: null,
        email: null,
        headline: null,
        tagline2: null,
        ussd: null,
        logo: null,
        bank: null,
        powered: null,
        stateLogo: null
      },
      dateRange:'For the period 1st Mar 2023 To 30th Mar 2023',
      dateTo:'',
      dateFrom:'',
      names:'',
      totalAmount:0,
      totalRecordCount:0,
      totalPendingCount: 0,
      totalArchivedCount: 0,
      totalInvoicedCount:0,
      totalEntriesAmount:0,
      subCountiesRevenue: [
        {
          subCountyName: null,
          recordCount: null,
          totalReceiptAmount: null,
          pendingCount: null,
          invoicedCount: null,
          archivedCount: null
        }
      ],


      entries: [{
        brimsCode:null,
        subCountyName:null,
        wardName:null,
        zone: null,
        businessID: null,
        renewalStatus: null,
        businessName: null,
        owner: null,
        ownerPhone: null,
        registeredOn: null,
        registeredBy: null,
        feeDescription: null,
        billNo: null,
        changesCount: null,
        billTotal: null,
        receiptAmount: null
      }],
      pageSize: 18, // Define how many records you want per page
    };
  },
  computed: {
    displayCount() {
      // If subCountiesRevenue has more than 6 items, reduce the slice count accordingly
      if (this.subCountiesRevenue.length > 6) {
        return 6 - (this.subCountiesRevenue.length - 6);
      }
      // Otherwise, return 6 records
      return 6;
    },

    // Split entries into pages dynamically
    paginatedEntries() {
      const pages = [];
      // Start from record 7 (index 6) for the first page
      const recordsFrom = this.entries.slice(this.displayCount); // Start from record number startFrom+1 (index startFrom)

      // Split the records from 7 into pages
      for (let i = 0; i < recordsFrom.length; i += this.pageSize) {
        pages.push(recordsFrom.slice(i, i + this.pageSize));
      }
      return pages;
    }
  },
  mounted() {

    this.names = sessionStorage.getItem("names")

    this.getPreviousRegisterReport()
    this.menuAccess()
  },
  methods: {

    menuAccess(){

      const data = new FormData();
      data.append("function", "menuAccess");
      data.append("menu", "SBP Bulk  Invoice Report");
      data.append("sessionNames", sessionStorage.getItem("sessionNames"));
      data.append("sessionIdNo",  sessionStorage.getItem("sessionIdNo"));
      execute(data,biller)
          .then(() => {
            // alert(res.data.message);
          })
          .catch(() => {
            // alert(e.message);
          });

    },
    formatDate(date) {
      let d = new Date(date);
      let day = d.getDate();
      let month = d.toLocaleString('en-US', { month: 'short' });
      let year = d.getFullYear();

      let suffix = '';
      if (day % 10 === 1 && day !== 11) {
        suffix = 'st';
      } else if (day % 10 === 2 && day !== 12) {
        suffix = 'nd';
      } else if (day % 10 === 3 && day !== 13) {
        suffix = 'rd';
      } else {
        suffix = 'th';
      }
      return `${day}${suffix} ${month} ${year}`;
    },
    showDate() {
      const dateObj = new Date();
      const currentDate =
          dateObj.getDate() +
          "/" +
          dateObj.getMonth() +
          "/" +
          dateObj.getFullYear();
      return currentDate;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getPreviousRegisterReport() {
      let params = {}
      params = JSON.parse(localStorage['params'])

      const data = new FormData();
      data.append("function", "getPreviousRegisterReport");
      data.append("dateFrom", params.dateFrom);
      data.append("dateTo", params.dateTo);
      data.append("page", params.currentPageFilter);
      data.append("rows_per_page",params.pageSizeFilter);
      data.append("keyword", params.searchFilter);
      data.append("subCountyName", params.subCountyNameFilter);
      data.append("wardName", params.wardNameFilter);
      data.append("zone", params.zoneNameFilter);
      data.append("renewalStatus", params.renewalStatus ? params.renewalStatus : ""); // Check existence of billingChange
      data.append("billingChange", params.billingChange ? params.billingChange : ""); // Check existence of billingChange
      data.append("billingChangeStatus", params.billingChangeStatus ? params.billingChangeStatus : ""); // Check existence of billingChangeStatusbillingChangeStatus
      execute(data, trade)
          .then((res) => {
            if (res.data.success) {
              this.subCountiesRevenue.splice(0)
              this.subCountiesRevenue = res.data.data.subCountiesRevenue;
              if(this.subCountiesRevenue.length>1){
                this.selectedSubCounty ='ALL SUB COUNTIES'
              }else {
                this.selectedSubCounty =this.subCountiesRevenue[0].subCountyName
              }
              this.entries = res.data.data.entries;

              this.county = res.data.data.county;
              this.recordYear = res.data.data.recordYear;

              //compute totals sub county
              this.totalAmount = this.subCountiesRevenue.reduce((acc, cur) => {
                let amountTotal = Number(cur.totalReceiptAmount);
                console.log("####\n")
                if (!isNaN(amountTotal)) {
                  return acc + amountTotal;
                } else {
                  return acc;
                }
              }, 0);
              this.totalRecordCount = this.subCountiesRevenue.reduce((acc, cur) => {
                let amountTotal = Number(cur.recordCount);
                console.log("####\n")
                if (!isNaN(amountTotal)) {
                  return acc + amountTotal;
                } else {
                  return acc;
                }
              }, 0);
              this.totalInvoicedCount = this.subCountiesRevenue.reduce((acc, cur) => {
                let amountTotal = Number(cur.invoicedCount);
                console.log("####\n")
                if (!isNaN(amountTotal)) {
                  return acc + amountTotal;
                } else {
                  return acc;
                }
              }, 0);
              this.totalPendingCount = this.subCountiesRevenue.reduce((acc, cur) => {
                let amountTotal = Number(cur.pendingCount);
                console.log("####\n")
                if (!isNaN(amountTotal)) {
                  return acc + amountTotal;
                } else {
                  return acc;
                }
              }, 0);

              this.totalArchivedCount = this.subCountiesRevenue.reduce((acc, cur) => {
                let amountTotal = Number(cur.archivedCount);
                console.log("####\n")
                if (!isNaN(amountTotal)) {
                  return acc + amountTotal;
                } else {
                  return acc;
                }
              }, 0);

              this.totalEntriesAmount = this.entries.reduce((acc, cur) => {
                let amountTotal = Number(cur.receiptAmount);
                console.log("####\n")
                if (!isNaN(amountTotal)) {
                  return acc + amountTotal;
                } else {
                  return acc;
                }
              }, 0);

            } else {
              alert(res.data.data.message);
            }
          })
          .catch((e) => {
            alert(e.message);
          });
    },
    numberFormat(num) {
      const britishNumberFormatter = new Intl.NumberFormat("en-GB");
      return britishNumberFormatter.format(num)
    }

  },
}
</script>

<style scoped>
* {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  -webkit-print-color-adjust: exact !important;
  /* Chrome, Safari */
  color-adjust: exact !important;
  /*Firefox*/
}

.print-btn {
  display: flex;
  z-index: 1000000000;
  position: fixed;
  background: #4aaa23;
  color: white;
  top: 30px;
  right: 30px;
  border-radius: 50%;
  padding: 1rem;
  margin: 0px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 0px 20px 4px rgb(136 136 136);
  cursor: pointer;
  transition: 0.4s;
}
.email-doc{
  top: 130px;
  background: #0063c0;
}
.print-btn img {
  height: 35px;
}
table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 10px;
}

tbody tr:nth-child(even) {
  background-color: #0000000a;
}

tbody tr:nth-child(odd) {
  background-color: white;
  color: black;
}

@media print {
  .print-btn {
    display: none;
  }
  body {
    margin: 0px !important;
    padding: 0px !important;
    background-color: white;
  }
  @page {
    /* size: auto; */
    /* auto is the initial value */
    /* this affects the margin in the printer settings */
    /* width: 210mm;*/
    /* height: 297mm; */
    size: A4 portrait;
    margin: 0px;
    margin-bottom: 0px;
    margin: 0px;
  }
}

@page {
  /* size: auto; */
  /* auto is the initial value */
  /* this affects the margin in the printer settings */
  /* width: 210mm;
      height: 297mm; */
  size: A4 portrait;
  margin: 0px;
  margin-bottom: 0px;
  margin: 0px;
}
</style>

